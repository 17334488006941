import React from "react"
import * as newsletterTileStyles from "./svgTiles.module.scss" 
import NewsletterLogo from "../../assets/newsletter.svg"
import TicketsLogo from "../../assets/tickets.svg"

const getLogo = (type) => {
  if(type === "newsletter") {
    return <div className={`${newsletterTileStyles.logo} ${newsletterTileStyles.newsletterLogo}`}><NewsletterLogo /></div>
  } else if(type === "tickets") {
    return <div className={`${newsletterTileStyles.logo} ${newsletterTileStyles.ticketsLogo}`}><TicketsLogo /></div>
  }
}

function TileLayout(props) {
  const {children, isSmall, prio, type} = props
  return(
    <div className={`${isSmall ? newsletterTileStyles.small: newsletterTileStyles.tall} ${prio===1 ? newsletterTileStyles.prio1: ""} ${prio===2 ? newsletterTileStyles.prio2: ""} ${prio!==1 && prio!==2 ? newsletterTileStyles.prioDefault: ""} `}>
                <div className={newsletterTileStyles.newsletterTile}>
                    {getLogo(type)}
                    {children}
                </div>
        </div>
  )
}

export function NewsletterTile(props) {
  const {isSmall, prio} = props
  return (
    <TileLayout isSmall={isSmall} prio={prio} type="newsletter">
      <div className={newsletterTileStyles.content}>
        <h2 className={newsletterTileStyles.heading}>NEWSLETTER</h2>
        <h2 className={newsletterTileStyles.subheading}>ABONNIEREN</h2>
      </div>
    </TileLayout>
  ) 
}

export function TicketsTile(props) {
  const {isSmall, prio} = props
  return(
    <TileLayout isSmall={isSmall} prio={prio} type="tickets">
      <div className={newsletterTileStyles.content}>
        <h2>TICKETS</h2>
      </div>
    </TileLayout>
  )
}
